import { render, staticRenderFns } from "./MunicipalwastePassport.vue?vue&type=template&id=40304b4a&scoped=true"
import script from "./MunicipalwastePassport.vue?vue&type=script&lang=js"
export * from "./MunicipalwastePassport.vue?vue&type=script&lang=js"
import style0 from "./MunicipalwastePassport.vue?vue&type=style&index=0&id=40304b4a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40304b4a",
  null
  
)

export default component.exports